<template>
  <div>
    <div id="id-page" class="flex-column">
      <div class="flex-row">
        <h1 class="h1">First Time Login</h1>
        <primary-button
          title="Refresh Account"
          class="margin-left-auto margin-top margin-bottom"
          v-on:click="triggerLogin()"
        />
      </div>
      <h4 class="h4">Get Started:</h4>
      <p>
        Your account hasn't been associated with a dealership yet. To do this, have a user with management permissions
        login to their account and go to the "Permissions" Tab. Click on the "+ Add new appraiser" button and click
        "Claim." You'll need to use the follow verification codes in order to be claimed. Remember your PIN because it
        won't be able to be seen again if you leave this page. It'll also be the PIN you use to login to the app. Your
        PIN can be reset once you are registered with a dealership by logging in to the app and going to the "Profile"
        section.
      </p>

      <div
        class="shadow flex-column margin-top margin-bottom padding rounded-corners margin-left-auto margin-right-auto"
      >
        <h4 class="h4">Generate Validation Codes</h4>
        <div class="flex-row margin-top margin-bottom">
          <div v-if="dealerId" class="flex-column margin-right-auto margin-left">
            <label class="label">Dealer ID:</label>
            <p class="body-2">{{ dealerId }}</p>
          </div>
          <div v-if="pin" class="flex-column margin-right">
            <label class="label">PIN:</label>
            <p class="body-2">{{ pin }}</p>
          </div>
        </div>
        <primary-button v-on:click="generatePINPressed()" class="margin-top" title="Generate New Codes" />
      </div>
      <error-modal v-if="error" :errors="[error]" @cancel="error = null" @cancelAll="error = null" />
    </div>
  </div>
</template>

<script>
import { login, generatePIN } from "../../api/Auth.js";
import ErrorModal from "../../components/Modals/v2/ErrorModal.vue";
import PrimaryButton from "../../components/Buttons/PrimaryButton.vue";
import { mapActions } from "vuex";

export default {
  name: "FirstTimeLogin",
  components: { ErrorModal, PrimaryButton },
  data: function () {
    return {
      dealerId: "",
      pin: "",
      error: null
    };
  },
  methods: {
    generatePINPressed: function () {
      generatePIN()
        .then(response => {
          this.dealerId = response.id;
          this.pin = response.pin;
        })
        .catch(error => {
          this.error = error;
        });
    },
    triggerLogin: function () {
      login({})
        .then(data => {
          this.handleLoginResponse(data);
        })
        .catch(error => {
          this.is_logging_in = false;
          this.error = [error];
        });
    },
    handleLoginResponse(data) {
      console.log(data);
      this.is_logging_in = false;
      if (data.key) {
        this.setAuthToken(data.key);
      }
      if (data.profile) {
        let isDealership = data.profile.is_dealership;
        let isReseller = data.profile.is_reseller;
        let isSuperUser = data.profile.is_superuser;
        let next = this.$route.query.next;
        var routerPushName = "FirstTimeLogin";
        var propogateNext = false;
        this.setIsDealership(isDealership);
        this.setIsSuperUser(isSuperUser);
        if (isDealership) {
          this.setCurrentDealership(data.dealership);
          routerPushName = "SwitchDealer";
          propogateNext = true;
        } else if (isReseller) {
          this.setCurrentReseller(data.reseller);
          routerPushName = "Search";
        } else if (isSuperUser) {
          routerPushName = "SuperUserPermissions";
        }

        if (next && !propogateNext) {
          this.$router.push(next);
        } else if (next && propogateNext) {
          this.$router.push({ name: routerPushName, query: { next: next } });
        } else {
          this.$router.push({ name: routerPushName });
        }
      }
    },
    ...mapActions({
      setAuthToken: "authStore/setAuthToken",
      setIsDealership: "authStore/setIsDealership",
      setIsSuperUser: "authStore/setIsSuperUser",
      setCurrentDealership: "dealershipStore/setCurrentDealership",
      setCurrentReseller: "resellerStore/setCurrentReseller"
    })
  },
  mounted: function () {
    this.triggerLogin();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#id-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
  padding-right: $PADDING_X4;
}
</style>
