<template>
  <button id="btn" :disabled="disabled">
    <p style="margin: auto">
      {{ title }}
    </p>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: ["title", "disabled"]
};
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

#btn {
  display: flex;
  padding: $BUTTON_PADDING;
  color: white;
  background-color: $PRIMARY_BUTTON_COLOR;
  font: normal 16px $FONT_SF_PRO_DISPLAY_SEMI_BOLD;
  line-height: 16px;
  border: 1px solid $PRIMARY_BUTTON_COLOR;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    -webkit-text-stroke-width: $BUTTON_HOVER_STROKE_WIDTH;
  }

  &:disabled {
    background-color: gray;
    border: 0;
  }
}
</style>
